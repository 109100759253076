import { CaseQuestionsAnswers } from 'types/createCase';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import { Tooltip } from '@northstar/core';

type Props = {
    questionsAnswers: CaseQuestionsAnswers[] | null;
};

const MAX_CHARACTERS = 200;
const MAX_LINES = 7;

const answerStyles = {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: MAX_LINES,
};

const QuestionsAnswers = ({ questionsAnswers }: Props) => {
    if (!questionsAnswers || questionsAnswers?.length === 0) return null;

    return (
        <>
            {questionsAnswers.map(({ answerText, questionText }, index) => {
                return (
                    <Box marginBottom={3} key={index}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            marginBottom={0.5}
                        >
                            {questionText}
                        </Typography>
                        {answerText.length >= MAX_CHARACTERS ? (
                            <Tooltip
                                title={answerText}
                                arrow
                                placement="bottom"
                            >
                                <Typography variant="body1" sx={answerStyles}>
                                    {answerText}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography variant="body1">
                                {answerText}
                            </Typography>
                        )}
                    </Box>
                );
            })}
        </>
    );
};

export { QuestionsAnswers };

import { Grid } from '@northstar/core';
import { Suspense, useEffect, useRef, useState } from 'react';
import { ProductPermission } from 'types/permissions';
import { objectOrderBy } from 'utils/objectOrderBy';
import {
    AnswerOption,
    LookupResponse,
    CaseQuestionsAnswers,
} from 'types/createCase';
import { useFormContext } from 'react-hook-form';

import { useServiceRequest } from 'hooks/useServiceRequest';
import { DynamicFormField } from 'types/formFields';
import { DynamicFormFields } from 'pages/Support/components/Create/DynamicFormFields';
import { LazyFormAutocomplete } from 'components/FormFields';
import { FormFieldLoading } from 'components/FormFieldLoading';
import { getProductMatchCriteria } from '../DynamicFormFields/getProductMatchCriteria';
import { createFieldState } from '../DynamicFormFields/createFieldState';
import { createValidationRules } from '../DynamicFormFields/createValidationRules';
import { conditionalValidationFields } from '../validationSchema';
import { getChainedFields } from '../DynamicFormFields/getChainedFields';
import { conditionalFieldsIds } from '../ConditionalFormFields';
import { getQuestionAnswerReqData } from '../getQuestionAnswerReqData';

type Props = {
    defaultSeverityLevel: string;
    handleDraftChange: (data: any) => void;
    initialDynamicFields: CaseQuestionsAnswers[];
    lookUpOptions: LookupResponse[];
    productBrandId: string | undefined;
    saveQAResponses: (
        data: CaseQuestionsAnswers[],
        removedFieldIds?: string[]
    ) => void;
    selectedUserProducts: ProductPermission[] | undefined;
    setLookUpOptions: (options: LookupResponse[]) => void;
    setShowConditionalFields: (value: boolean) => void;
    updateFormValidation: (removedFields: any, validation: any) => void;
};

const ProductSelection = ({
    defaultSeverityLevel,
    handleDraftChange,
    initialDynamicFields,
    lookUpOptions,
    productBrandId,
    saveQAResponses,
    selectedUserProducts,
    setLookUpOptions,
    setShowConditionalFields,
    updateFormValidation,
}: Props) => {
    const {
        getValues: getFormValues,
        setValue: setFieldValue,
        unregister,
        watch,
    } = useFormContext();

    const formValues = getFormValues();

    const accountChangeWatcher = watch('selectedAccount');

    const { questionCriteria, questionDefinitions, answerOptions } =
        useServiceRequest();

    const [dynamicFormFields, setDynamicFormFields] = useState<
        DynamicFormField[]
    >([]);

    const productRef = useRef<HTMLElement>(null);

    const cleanDynamicFieldsStates = () => {
        const fieldNames = [] as string[];

        if (dynamicFormFields.length === 0) return;

        dynamicFormFields.forEach((item) => {
            fieldNames.push(item.props.name);
            unregister(item.props.name);
        });

        setDynamicFormFields([]);
        saveQAResponses([]);
        setShowConditionalFields(true);
        updateFormValidation(fieldNames, conditionalValidationFields);
    };

    const triggerDraftSave = ({
        updatedValues,
        removedFieldIds,
        productValue = {},
        formFields = dynamicFormFields,
    }: any) => {
        const caseQuestionAnswerRequests = getQuestionAnswerReqData({
            answerOptions,
            formValues: updatedValues,
            lookUpOptions,
            questionCriteria,
            questionDefinitions,
            formFields,
        });

        saveQAResponses(caseQuestionAnswerRequests, removedFieldIds);
        handleDraftChange(productValue);
    };

    const processProductValue = (value: ProductPermission | null) => {
        if (questionCriteria && questionDefinitions && answerOptions && value) {
            const criteria = getProductMatchCriteria(value, questionCriteria);

            if (!criteria) {
                cleanDynamicFieldsStates();
                return null;
            }

            const definition = questionDefinitions.find(
                (qDItem) => qDItem.id === criteria.questionId
            );

            if (!definition) {
                cleanDynamicFieldsStates();
                return null;
            }

            const newFormFields = [] as DynamicFormField[];
            const fieldState = createFieldState(criteria, definition);

            if (fieldState.elementType === 'dropDown') {
                const fieldOptions = answerOptions.filter(
                    (aOItem) => aOItem.questionId === definition.id
                );

                fieldState.props.options = objectOrderBy(fieldOptions, 'name');
            }

            newFormFields.push(fieldState);

            if (formValues[criteria.questionId]) {
                const optItem = {
                    id: formValues[criteria.questionId],
                    questionId: criteria.questionId,
                } as AnswerOption;

                const chainedFormItems = getChainedFields({
                    startOption: optItem,
                    dynamicFormFields,
                    formValues,
                });

                newFormFields.push(...chainedFormItems);
            } else {
                setFieldValue(
                    fieldState.props.name,
                    definition.defaultValue || ''
                );

                const newValidation = createValidationRules([fieldState]);

                updateFormValidation(conditionalFieldsIds, newValidation);
            }

            const orderedByKey = objectOrderBy(newFormFields, 'displayOrder');

            setDynamicFormFields(orderedByKey);
            setShowConditionalFields(false);

            conditionalFieldsIds.forEach((item) => {
                setFieldValue(item, '');
            });

            const draftValues = {
                [fieldState.props.name]: definition.defaultValue || '',
            };

            return {
                draftValues,
            };
        }

        return null;
    };

    const handleProductChange = ({
        data,
    }: {
        name: string;
        data: ProductPermission | null;
    }) => {
        const processResult = processProductValue(data);

        if (!data || !processResult) {
            cleanDynamicFieldsStates();
            handleDraftChange({ name: 'productId', value: data });
            return;
        }

        const productValue = {
            name: 'productId',
            value: data,
        };

        if (processResult) {
            triggerDraftSave({
                updatedValues: processResult.draftValues,
                productValue,
            });
        }
    };

    useEffect(() => {
        cleanDynamicFieldsStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountChangeWatcher]);

    return (
        <>
            <Grid item xs={12}>
                <Suspense fallback={<FormFieldLoading />}>
                    <LazyFormAutocomplete
                        handleOnChange={handleProductChange}
                        fieldRef={productRef}
                        inputLabel="Product"
                        labelKey="productName"
                        name="productId"
                        options={selectedUserProducts}
                        placeholder="Select Product *"
                        valueKey="softwareProductId"
                    />
                </Suspense>
            </Grid>
            <DynamicFormFields
                defaultSeverityLevel={defaultSeverityLevel}
                dynamicFormFields={dynamicFormFields}
                handleDraftChange={handleDraftChange}
                initialDynamicFields={initialDynamicFields}
                lookUpOptions={lookUpOptions}
                productBrandId={productBrandId}
                saveQAResponses={saveQAResponses}
                setDynamicFormFields={setDynamicFormFields}
                setLookUpOptions={setLookUpOptions}
                setShowConditionalFields={setShowConditionalFields}
                triggerDraftSave={triggerDraftSave}
                updateFormValidation={updateFormValidation}
            />
        </>
    );
};

export { ProductSelection };

import { LookupResponse } from 'types/createCase';
import axios from 'utils/axios';

type Props = {
    lookUpName: string;
    productBrandId: string | undefined;
    selectedAccount: string;
};

const getLookUpResponse = async ({
    lookUpName,
    productBrandId,
    selectedAccount,
}: Props) => {
    try {
        const { data } = await axios<LookupResponse[]>(
            `/service_requests/lookup_response/${lookUpName}?brand=${productBrandId}`,
            {
                headers: {
                    portalAccountIds: selectedAccount,
                },
            }
        );

        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export { getLookUpResponse };
